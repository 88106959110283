import { graphql, useStaticQuery } from "gatsby"
import { faHyphen } from "@fortawesome/pro-regular-svg-icons"
import WrappedLink from "./WrappedLink"

const NavLinksChallengeEvents = ({ onClick }) => {
  const data = useStaticQuery(graphql`
    query ChallengesNavLinkQuery {
      allFile(filter: { relativeDirectory: { glob: "guides/challenge" } }) {
        nodes {
          relativeDirectory
          name
        }
      }
    }
  `)

  const style = {
    fontSize: ".9rem",
  }

  const images = data.allFile.nodes
  return images.map((img: { name: string; relativeDirectory: string }) => {
    return (
      <WrappedLink
        id={img.name}
        onClick={onClick || undefined}
        to={`/${img.relativeDirectory}/${img.name
          .replace(/\s/g, "-")
          .toLowerCase()}`}
        key={img.name}
        sx={{ pl: 5 }}
        icon={faHyphen}
        text={img.name}
        style={style}
      />
    )
  })
}

export default NavLinksChallengeEvents
