import NavLinksClassQuest from "./NavLinksClassQuest"
import NavLinksRareQuests from "./NavLinksRareQuests"
import NavLinksSeasonalEvents from "./NavLinksSeasonalEvents"
import NavLinksChallengeEvents from "./NavLinksChallengeEvents"
import WrappedLink from "./WrappedLink"

export { WrappedLink }

export const Links = {
  Class: NavLinksClassQuest,
  Rare: NavLinksRareQuests,
  Seasonal: NavLinksSeasonalEvents,
  Challenges: NavLinksChallengeEvents,
}

export default Links
