import styled from "@emotion/styled"
import "typeface-roboto"
import "typeface-oswald"

const GlobalStyles = styled.div`
  @font-face {
    font-family: "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
    font-display: block;
  }
  small {
    font-size: 0.8rem;
    color: #666;
  }
`

export default GlobalStyles
